.ui-select-choices-row p,
.ui-select-match-item p,
.ui-select-toggle p {
    margin: 0;
}

.ui-select-loading:after {
    z-index: 10;
    border: solid rgba(9, 62, 78, .2);
    border-top-color: #189dc8;
    border-radius: 50%;
    -webkit-animation: spin 1s infinite linear;
    -moz-animation: spin 1s infinite linear;
    animation: spin 1s infinite linear;
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    width: 40px;
    height: 40px;
    margin: -20px 0 0 -20px;
    border-width: 5px;
}

.single-select.ui-select-container.ng-not-empty span.form-control.ui-select-toggle,
.ui-select-match {
    height: auto;
}

.ui-select-multiple.ng-empty {
    height: 34px;
}

.form-inline .form-control.ui-select-toggle {
    width: 100%;
}

.ui-select-multiple .input-xs {
    font-size: inherit;
}

input.ui-select-search.input-xs {
    width: 100% !important;
}

.ui-select-choices-row p, .ui-select-match-item p, .ui-select-toggle p {
    line-height: 1.5;
}

.ui-select-multiple.ui-select-bootstrap .ui-select-match .close {
    line-height: 1;
}

.ui-select-multiple .ui-select-match-close + span > div,
.ui-select-multiple .ui-select-match-close + span > p {
    padding-right: 15px;
}

.ui-select-container .caret + a{
    padding-top: 1px !important;
    color: #444 !important;
}